<!--招贤纳士-->
<template>
    <div class="page">
        <HeadContent>
            <img slot="bgImg" src="./images/talent/组 631.png" style="width: 100%;background-color: #666">
            <div slot="title" style="width: 100%">人尽其才,共同发展
                <div style="font-size: 30px;
font-family: FZLanTingHeiS-B-GB;
font-weight: 400;
color: #FFFFFF;opacity: 0.6;margin-top: 30px;">
                    <div>Give full scope to the talents</div>
                    <div>Common development</div>
                </div>
            </div>
        </HeadContent>
        <div class="rjqcgtfz">
            <div>人尽其才,共同发展</div>
            <div>
                我们鼓励员工将自身发展与公司长期规划相结合，<br>
                我们为员工提供多种成长途径和发展模式；<br>
                我们努力为每一位员工创造良好的成长条件；<br>
                我们给每一位立志成才的员工提供广阔的发展空间，
            </div>
        </div>

        <!--    人才理念s-->
        <div class="rcln">
            <div class="rcln-l">
                <div class="mtitle">人才理念</div>
                <div v-html="carouseTxtList[carouselAc].btxt">
                </div>
                <div>
                    {{carouseTxtList[carouselAc].stxt}}
                </div>
                <gk-arrow @rFn="rFn" @lFn="lFn"></gk-arrow>
            </div>
            <div class="rcln-r">
                <el-carousel ref="elCar" type="card" height="500px" indicator-position="none" arrow="never"
                             :autoplay="false" @change="carouselChange">
                    <el-carousel-item>
                        <div class="seippitem">
                            <img src="./images/talent/蒙版组 249.png" alt="">
                            <span>创造</span>
                        </div>
                    </el-carousel-item>
                    <el-carousel-item>
                        <div class="seippitem">
                            <img src="./images/talent/蒙版组 252.png" alt="">
                            <span>发展</span>
                        </div>
                    </el-carousel-item>
                    <el-carousel-item>
                        <div class="seippitem">
                            <img src="./images/talent/蒙版组 253.png" alt="">
                            <span>实现</span>
                        </div>
                    </el-carousel-item>
                    <el-carousel-item>
                        <div class="seippitem">
                            <img src="./images/talent/蒙版组 212.png" alt="">
                            <span>提供</span>
                        </div>
                    </el-carousel-item>
                </el-carousel>

                <!--        <div class="active">-->
                <!--          <img src="" alt="">-->
                <!--          <span>提供</span>-->
                <!--        </div>-->
                <!--        <div>-->
                <!--          <img src="" alt="">-->
                <!--          <span>发展</span>-->
                <!--        </div>-->
            </div>
        </div>
        <!--    人才理念e-->

        <!--    选择我们s-->
        <div class="xzwm">
            <div class="xzwm-t">
                选择我们
            </div>
            <div class="xzwm-c">
                <div>
                    <img src="./images/talent/风格的的风格-01.png" alt="">
                    <span>预约咨询</span>
                </div>
                <div>
                    <img src="./images/talent/风格的的风格-02.png" alt="">
                    <span>电话咨询</span>
                </div>
            </div>
        </div>
        <!--    选择我们e-->


    </div>
</template>

<script>
    export default {
        data() {
            return {
                carouselAc: 0,
                reqData: {
                    liaison: "",
                    enterprise: "",
                    phone: "",
                    email: ""
                },
                carouseTxtList: [
                    {
                        btxt: "为人才创造成长<br>条件",
                        stxt: "创造条件"
                    },
                    {
                        btxt: "与人才发展自身<br>潜能",
                        stxt: "发展潜能"
                    },
                    {
                        btxt: "与人才实现自身<br>价值",
                        stxt: "实现价值"
                    },
                    {
                        btxt: "为人才提供成长<br>平台",
                        stxt: "提供平台"
                    }
                ]
            }
        },
        methods: {
            rFn() {
                this.$refs.elCar.next()
            },
            lFn() {
                this.$refs.elCar.prev()
            },
            carouselChange(a) {
                console.log(a)
                this.carouselAc = a
            }
        }
    }
</script>

<style lang="scss" scoped>
    .rjqcgtfz {
        width: 100%;
        @include flexCenter;
        height: 480px;
        background: #F5F5F5;
        flex-direction: column;

        div:nth-child(1) {
            font-size: 50px;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 60px;
            color: #606060;
        }

        div:nth-child(2) {
            margin-top: 19px;
            @include flexCenter;
            flex-direction: column;
            text-align: center;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 30px;
            color: #505050;
        }
    }

    //人才理念 s
    .rcln {
        @include page1200;
        padding-top: 90px;
        padding-bottom: 90px;
        display: flex;

        .rcln-l {
            width: 501px;
            height: 482px;

            div:nth-child(1) {
                margin-top: 47px;
            }

            div:nth-child(2) {
                margin-top: 35px;
                margin-bottom: 8px;
                width: 501px;
                height: 185px;
                font-size: 50px;
                font-family: PingFang SC;
                font-weight: bold;
                line-height: 65px;
                color: #606060;
            }
        }

        div:nth-child(3) {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #606060;
        }

        .gkarrow {
            margin-left: 305px;
        }

        .rcln-r {
            display: flex;
            padding: 0 0 0 90px;
            padding-top: 60px;
            width: 600px;

            .el-carousel {
                width: 100%;
            }

            .seippitem {
                width: 220px;
                height: 358px;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                    background-color: #99a9bf;
                }

                span {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    @include flexCenter;
                    display: inline-flex;
                    width: 100%;
                    height: 86px;
                    background: #1320E8;
                    font-size: 35px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    opacity: 0.5;
                    letter-spacing: 2px;
                }
            }

            .active {
                z-index: 1;
                transform: scale(1.5);
            }
        }
    }

    //人才理念 e

    //选择我们s
    .xzwm {
        width: 100%;
        background: #FBFBFB;
        padding: 90px 0;
        @include flexCenter;
        flex-direction: column;

        .xzwm-t {
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 0px;
            color: #333333;
        }

        .xzwm-c {
            margin-bottom: 28px;
            display: flex;
            justify-content: space-between;
            width: 574px;

            div {
                width: 146px;
                height: 50px;
                display: flex;
                margin-top: 28px;

                img {
                    width: 50px;
                    height: 50px;
                }

                span {
                    display: inline-flex;
                    width: 96px;
                    height: 100%;
                    @include flexCenter
                }
            }
        }
    }

    //选择我们e


</style>
